<script setup lang="ts">
import { computed, inject, ref } from 'vue'

import type { EquivalenceGrid, PaymentType } from '@/types'
import { getFormattedAmount } from '@/utils/currency'

const props = defineProps<{
  grid: EquivalenceGrid
  closestAmount: number
  paymentType: PaymentType
  modelValue?: number
  indexToFocus: number
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: number): void
  (e: 'update:keyboardFocusedAmount', amount: number): void
}>()

const language = inject('language') as string
const carousel = ref<HTMLDivElement>()

const carouselTransformStyle = computed(() => {
  // Size of all previous cards + size of the gap between them
  const translateX = `translateX(calc(-${props.indexToFocus} * var(--equivalence-card-width) - ${props.indexToFocus}em))`
  return { transform: translateX }
})

function selectEquivalence(amount: number) {
  emit('update:modelValue', amount)
}

function onKeyboardFocusOnCard(amount: number) {
  emit('update:keyboardFocusedAmount', amount)
  carousel.value?.parentElement?.scroll(0, 0)
}

function onBlurFromCard() {
  if (!carousel.value?.contains(document.activeElement)) {
    emit('update:keyboardFocusedAmount', 0)
  }
}
</script>

<template>
  <div
    ref="carousel"
    class="equivalence-carousel"
    :class="{ 'opacity-50': modelValue === 0 }"
    :style="carouselTransformStyle"
  >
    <div
      v-for="equivalence in grid.equivalences"
      :key="equivalence.amount"
      class="card equivalence-card"
      :class="{
        selected: equivalence.amount === modelValue,
        'equivalence-card-with-image': grid.format === 'image',
        'equivalence-card-text-only': grid.format === 'text',
      }"
      :aria-current="equivalence.amount === modelValue"
      :aria-labelledby="`equivalence-text-${equivalence.amount}-${paymentType} btn-${equivalence.amount}-${paymentType}`"
      tabindex="0"
      @focus="onKeyboardFocusOnCard(equivalence.amount)"
      @blur="onBlurFromCard"
      @click="selectEquivalence(equivalence.amount)"
      @keydown.enter.space.prevent="selectEquivalence(equivalence.amount)"
    >
      <div class="card-body">
        <img
          v-if="grid.format === 'image'"
          :src="equivalence.image_url"
          class="card-img-top"
          :alt="equivalence.image_alt"
        />
        <div v-else class="card-title">
          {{ getFormattedAmount(equivalence.amount, language) }}
        </div>
        <div :id="`equivalence-text-${equivalence.amount}-${paymentType}`" class="card-subtitle">
          {{ equivalence.description }}
        </div>
      </div>
    </div>
  </div>
</template>
